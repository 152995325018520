import React, { ReactNode, useEffect, useState } from "react";
import TableContent from "../../../../components/table-content";
import './provider-list-page.scss';
import Button from "../../../../components/button";
import { ReactComponent as AddIcon } from '../../../../assets/icons/add-icon.svg';
import { useNavigate } from "react-router-dom";
import { ReactComponent as BtcIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as UsdtIcon } from '../../../../assets/icons/usdt-rounded.svg';
import { apiService } from "../../../../services/api.service";
import { useSelector } from "react-redux";
import { loadingSliceActions, LoadingState } from "../../../../store/loading.slice";
import { AppStore } from "../../../../store/store";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../../components/loading-spinner";
import { UserState } from "../../../../store/user.slice";
import { WalletInfoDetails } from "../../../../models";

export interface AccountModel {
    id: number,
    accountNo: string,
    status: string;
    wallets?: Array<ReactNode>;
}


const ProviderListPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [accounts , setAccounts] = useState<AccountModel[]>([]);
    const { isLoading } = useSelector<AppStore, LoadingState>(state => state.loading);
    const { user } = useSelector<AppStore, UserState>(state => state.user)
    const isDirector = user?.roles?.includes('director');
    
    useEffect(() => {
        const getAccounts = async () => {
            dispatch(loadingSliceActions.startLoading())

            try {
                const response = await apiService.getAllAccounts();
                return response.data;
            } catch (e) {
                return [];
            } finally {
                dispatch(loadingSliceActions.stopLoading())
            }
        }

        getAccounts().then((accounts) => {
            setAccounts(accounts);
        }) 
    }, [user?.id])
    const columns = React.useMemo(
        () => [
            {
                Header: 'Provider Number',
                accessor: 'accountNo',
                Cell: ({ row }: any) => (
                    <div 
                        style={{ cursor: 'pointer'}}
                        onClick={() => navigate(`provider-details/${row.original.id}`)}
                    >
                        {row.original.accountNo}
                    </div>
                ),
            },
            {
                Header: ({ column }: any) => (
                    <div style={{ textAlign: 'center' }}>Status</div>
                ),
                accessor: 'status',
                Cell: ({ value, row }: { value: string, row:any}) => (
                    <div style={{ textAlign: 'center' }}>
                        <span
                          style={{ cursor: isDirector ? 'pointer' : 'default' }}
                        onClick={() => changeAccountStatus(row.original.id, value)} 
                        className={`status-chip-account-list ${value.toLowerCase()}`}>
                            {value}
                        </span>
                    </div>
                ),
            },
            {
                Header: 'Wallets',
                accessor: 'wallets',
                Cell: ({ value }: { value: WalletInfoDetails[] }) => (
                    <div style={{ display: 'flex', gap: '10px' }}>
                    {value.map((wallet, index) => {
                        let icon = null;
                        switch (wallet.asset) {
                            case 'ETH':
                                icon = <EthIcon key={index} />;
                                break;
                            case 'BTC':
                                icon = <BtcIcon key={index} />;
                                break;
                            case 'USDT':
                                icon = <UsdtIcon key={index} />;
                                break;
                            default:
                                icon = null; 
                                break;
                        }
                        return icon;
                    })}
                </div>
                ),
            },
        ],
        []
    );

    const changeAccountStatus = async (id: number, currentStatus: string) => {
        if(!isDirector) {
            return
        }
        const newStatus = currentStatus.toUpperCase() === 'ACTIVE' ? 'BLOCKED' : 'ACTIVE';
        
        try {
            await apiService.updateAccountStatus(id, newStatus);
            setAccounts((prevAccounts) => 
                prevAccounts.map((account) => 
                    account.id === id ? { ...account, status: newStatus } : account
                )
            );
        } catch (error) {
            console.error('Failed to update account status', error);
        }
    };
    const handleAddAccount = () => {
        navigate('add-provider')
    }
    return (
        <div className="account-details-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Provider List</span>
                <Button
                    label="Add Provider"
                    icon={AddIcon}
                    onClick={handleAddAccount}
                ></Button>
            </div>
            <div className="page-content">
            { isLoading ? <LoadingSpinner/> :     <TableContent columns={columns} data={accounts || []}></TableContent> }
            </div>
        </div>
    )
}

export default ProviderListPage;