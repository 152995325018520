import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import './custom-chart.scss';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend,Filler);

const data = {
    labels: ['5k', '10k', '15k', '20k', '25k', '30k', '35k', '40k', '45k', '50k', '55k', '60k'],
    datasets: [
        {
            label: 'Transaction Volume',
            data: [22, 30, 50, 90, 60, 45, 80, 50, 40, 70, 65, 70],
            borderColor: '#3E82F7',
            borderWidth: 2,
            pointBackgroundColor: '#3E82F7',
            pointBorderColor: '#3E82F7',
            pointHoverBackgroundColor: '#F2AC34', 
            pointHoverBorderColor: '#F2AC34', 
            pointHoverRadius: 5, 
            tension: 0.1, 
            fill: true,
            backgroundColor: (context:any) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                
                if (!chartArea) {
                    return null;
                }

                const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                gradient.addColorStop(0, 'rgba(62, 130, 247, 0.5)'); 
                gradient.addColorStop(1, 'rgba(62, 130, 247, 0)');   
                return gradient;
            },
        },
    ],
};

const calculateMinYValue = (firstValue: number) => {
    return Math.floor(firstValue / 20) * 20;
};

const firstDataValue = data.datasets[0].data[0] as number; 

const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
        x: {
            grid: {
                display: false, 
            },
            ticks: {
                color: '#FFF', 
                padding: 30, 
            },
        },
        y: {
            grid: {
                color: '#E2E2E2', 
                lineWidth: 0.2, 
                drawBorder: false, 
            },
            beginAtZero: false,
            min: calculateMinYValue(firstDataValue), 
            ticks: {
                stepSize: 20,
                padding: 30,
                color: '#FFF', 
                callback: (value: number | string) => `${value}%`, 
            },
            suggestedMax: 100, 
        },
    },
    plugins: {
        tooltip: {
            enabled: true,
            mode: 'index' as const,
            intersect: false,
        },
        legend: {
            display: false, 
        },
    },
};

const CustomChart = () => {
    return (
        <div className='custom-chart-container' >
            <div className='custom-chart'>
                <Line data={data} options={options} />
            </div>
        </div>
    );
};

export default CustomChart;
