import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { attachAxiosLogInterceptors, attachAxiosApiInterceptors } from '../utils/axios-utils';
import { PaymentModel, RegisterAccount, UserModel, WalletCrypto } from '../models';
import { Dispatch } from '@reduxjs/toolkit';
import { AccountModel } from '../router/private/account-management/provider-list/provider-list-page';

class ApiService {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      // #TODO add to config
      baseURL: 'https://bfireforge.realgrowsoft.com/',
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
      }
    });

  }

  attachDispatchToAxiosApi(dispatch: Dispatch<any>) {
    attachAxiosApiInterceptors(this.client, dispatch)
  }


  getUserDetails(userId: string): Promise<AxiosResponse<UserModel>> {
    return this.client.get(`/auth/${userId}`)

  }




  getAllAccounts(): Promise<AxiosResponse<AccountModel[]>> {
     return this.client.get('account/getAllAccounts');
  }

  createAccount(account: RegisterAccount): Promise<AxiosResponse> {
    return this.client.post('account/createAccount', account);
  }

  getWalletByAccount(accountId: number) {
    return this.client.get(`wallet/getByAccountId/${accountId}`);
  }

  updateAccountStatus(id:number,status: string) {
    return this.client.put(`account/updateAccountStatusById/${id}/${status}`);
  }




  getAllWallets() {
    return this.client.get('wallet/getAllWallets');
  }

  getWalletBalance(address: string) {
    return this.client.get(`crypto/balanceByWallet/${address}`)
  } 

  updateWalletStatus(id:number,status: string) {
    return this.client.put(`wallet/updateWalletStatusById/${id}/${status}`);
  }


  getTransactionsByWallet(walletAddress: string) {
    return this.client.get(`crypto/transactionsList/${walletAddress}`);
  }

  getTransactionByHash(hash: string) {
    return this.client.get(`crypto/transaction/${hash}`)
  }


  getWalletByTypeAndAsset(type: string, asset: string) {
    return this.client.get(`wallet/getByTypeAndAsset/${type}/${asset}`);
  } 

  createInternalWallet(wallet: WalletCrypto) {
    return this.client.post('wallet/createInternalWallet', wallet);
  }






  getAllPaymentByUser() {
    return this.client.get(`/payment/getAllPaymentsByUser`);
  }


  getAllPayments() {
    return this.client.get('/payment/getAllPayments');
  }


  getPaymentsByUser(userId: number) {
    return this.client.get(`payment/getPaymentApprovalsByUser/${userId}`);

    
  }

  createPayment(data: PaymentModel) {
    return this.client.post('payment/createPayment', data);
  }

  getPaymentDetails(id:string) {
    return this.client.get(`payment/getPayment/${id}`)
  }

  getPaymentApprovalsByPayment(paymentId: string) {
    return this.client.get(`payment/getPaymentApprovalsByPayment/${paymentId}`)
  }

  getAllPaymentsWhereUserIdIsApprover(userId: string) {
    return this.client.get(`payment/getAllPaymentsWhereUserIdIsApprover/${userId}`)
  }

  updatePaymentApproval(id: string, status: string) {
    return this.client.put(`/payment/updatePaymentApproval/${id}/${status}`)
  }
}

const apiService = new ApiService();
export { apiService };