import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as BtcIcon } from '../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthIcon } from '../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as UsdtIcon } from '../../../assets/icons/usdt-rounded.svg';
import TableContent from '../../../components/table-content';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../services/api.service';
import { AccountModel } from '../account-management/provider-list/provider-list-page';
import { useSelector } from 'react-redux';
import { loadingSliceActions, LoadingState } from '../../../store/loading.slice';
import { AppStore } from '../../../store/store';
import { UserState } from '../../../store/user.slice';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../../components/loading-spinner';
import { WalletInfoDetails } from '../../../models';
import CustomChart from '../../../components/custom-chart';





const columnsRecent = [
    {
        Header: 'Method',
        accessor: 'method',
        Cell: ({ value }: { value: string }) => (
            <span className={`${value.toLocaleLowerCase()}`}>{value}</span>
          ),
    },
    // {
    //     Header: 'Status',
    //     accessor: 'status',
    //     Cell: ({ value }: { value: string }) => {
    //         let statusClass = '';
    //         switch (value) {
    //             case 'Approved':
    //                 statusClass = 'open';
    //                 break;
    //             case 'Pending':
    //                 statusClass = 'suspended';
    //                 break;
    //             case 'Blocked':
    //                 statusClass = 'blocked';
    //                 break;
    //             default:
    //                 statusClass = 'unknown';
    //         }
    //         return <span className={`status-chip-account-details ${statusClass}`}>{value}</span>;
    //     },
    // },
    {
        Header: 'Block',
        accessor: 'block',
        Cell: ({ value, row }: { value: string, row: any }) => (
            <>
                <div>{value}</div>
                <div className="secondary-text">{row.original.confirmations}</div>
            </>
        ),
    },
    {
        Header: 'Timestamp',
        accessor: 'timestamp',
        Cell: ({ value, row }: { value: string, row: any }) => (
            <>
                <div>{value}</div>
                <div className="secondary-text">{row.original.relativeTime}</div>
            </>
        ),
    },
    {
        Header: 'From',
        accessor: 'from',
        Cell: ({ value, row }: { value: string, row: any }) => {return <>{
            row.original.fromAlias ? (<div className="from-to flex-column">
                <span>{row.original.fromAlias}</span>
                <span className='truncated-text secondary-text'>{value}</span>
            </div>) : 
            (<div className="from-to flex-column">
                <span>{value}</span>
            </div>)
        }</>}
        ,
    },
];

const dataRecent = [
    {
        method: 'Sent',
        block: '64093050',
        confirmations: '356,336 block confirmations',
        timestamp: '08/06/2024, 09:16:30',
        relativeTime: '12 days ago',
        from: 'THESRF9s9o5PY4wPijP7BfP8K7xi5LFfu1',
        fromAlias: 'Test1'
    },
    {
        method: 'Received',
        block: '64093050',
        confirmations: '356,336 block confirmations',
        timestamp: '08/06/2024, 09:16:30',
        relativeTime: '12 days ago',
        from: 'THESRF9s9o5PY4wPijP7BfP8K7xi5LFfu1',
        fromAlias: 'Test2'
    },
    {
        method: 'Sent',
        block: '64093050',
        confirmations: '356,336 block confirmations',
        timestamp: '08/06/2024, 09:16:30',
        relativeTime: '12 days ago',
        from: 'THESRF9s9o5PY4wPijP7BfP8K7xi5LFfu1',
    },
];

const DashboardPage = () => {
    const columnsAccountList = useMemo(
        () => [
            {
                Header: 'Provider Number',
                accessor: 'accountNo',
                Cell: ({ row }: any) => (
                    <div
                        className='truncated-text'
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`provider-management/provider-details/${row.original.id}`)}
                    >
                        {row.original.accountNo}
                    </div>
                ),
            },
            {
                Header: ({ column }: any) => (
                    <div style={{ textAlign: 'center' }}>Status</div>
                ),
                accessor: 'status',
                Cell: ({ value }: { value: string }) => (
                    <div style={{ textAlign: 'center' }}>
                        <span className={`status-chip-account-list ${value.toLowerCase()}`}>
                            {value}
                        </span>
                    </div>
                ),
            },
            {
                Header: 'Wallets',
                accessor: 'wallets',
                Cell: ({ value }: { value: WalletInfoDetails[] }) => (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {value.map((wallet, index) => {
                            let icon = null;
                            switch (wallet.asset) {
                                case 'ETH':
                                    icon = <EthIcon key={index} />;
                                    break;
                                case 'BTC':
                                    icon = <BtcIcon key={index} />;
                                    break;
                                case 'USDT':
                                    icon = <UsdtIcon key={index} />;
                                    break;
                                default:
                                    icon = null;
                                    break;
                            }
                            return icon;
                        })}
                    </div>
                ),
            },
        ],
        []
    );

    const dispatch = useDispatch();
    const [accounts, setAccounts] = useState<AccountModel[]>([]);

    const { isLoading } = useSelector<AppStore, LoadingState>(state => state.loading);
    const { user } = useSelector<AppStore, UserState>(state => state.user)


    const navigate = useNavigate();

    useEffect(() => {
        const getAccounts = async () => {
            dispatch(loadingSliceActions.startLoading())

            try {
                const response = await apiService.getAllAccounts();
                return response.data;
            } catch (e) {
                return [];
            } finally {
                dispatch(loadingSliceActions.stopLoading())
            }
        }

        getAccounts().then((accounts) => {
            setAccounts(accounts);
        })
    }, [user?.id])

    return (
        <div className="dashboard-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Dashboard</span>
            </div>
            <div className="page-content flex-column flex-gap-20">
                <div className="table-header">
                    <span className="table-title">Provider List</span>
                </div>
                {isLoading ? <LoadingSpinner /> : <TableContent columns={columnsAccountList} data={accounts || []}></TableContent>}
            </div>
            <div className="page-content flex-column flex-gap-20">
                <div className="table-header">
                    <span className="table-title">Recent Transactions</span>
                </div>
                <TableContent columns={columnsRecent} data={dataRecent}></TableContent>
            </div>
            <div className="page-content flex-column flex-gap-20">
                <div className="table-header">
                    <span className="table-title">Transaction Details</span>
                </div>
                <CustomChart />
            </div>

        </div>
    )
}

export default DashboardPage;