import TableContent from "../../../../components/table-content";
import './wallet-management-page.scss';
import WalletCard from "../../../../components/wallet-card";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MPC } from '../../../../assets/icons/mpc-wallet.svg';
import { ReactComponent as DC } from '../../../../assets/icons/dc-wallet.svg';
import { ReactComponent as Embedded } from '../../../../assets/icons/embedded-wallet.svg';
import { useEffect, useState } from "react";
import { WalletInfoDetails } from "../../../../models";
import { apiService } from "../../../../services/api.service";
import { useSelector } from "react-redux";
import { loadingSliceActions, LoadingState } from "../../../../store/loading.slice";
import { AppStore } from "../../../../store/store";
import LoadingSpinner from "../../../../components/loading-spinner";
import { useDispatch } from "react-redux";
import { UserState } from "../../../../store/user.slice";





export enum WalletTypes {
  MPC = 'MPC',
  DC = 'Direct Custody',
  EMB = 'Embedded'
}


const walletCards = [
  {
    walletType: WalletTypes.MPC,
    total: 40689,
    percentageChange: 8.5,
    description: 'Up from yesterday',
    icon: <MPC />
  },
  {
    walletType: WalletTypes.DC,
    total: 12500,
    percentageChange: 2.1,
    description: 'Up from yesterday',
    icon: <DC />
  },
  {
    walletType: WalletTypes.EMB,
    total: 89500,
    percentageChange: -4.3,
    description: 'Down from yesterday',
    icon: <Embedded />
  },
];





const WalletManagementPage = () => {

  const [ wallets, setWallets ] =  useState<WalletInfoDetails[]>([]);

  const navigate = useNavigate();
  
  const { isLoading } = useSelector<AppStore, LoadingState>(state => state.loading)
  
  const { user } = useSelector<AppStore, UserState>(state => state.user)

  const dispatch = useDispatch();


  useEffect(() => {

    const getWallets = async () => {
      dispatch(loadingSliceActions.startLoading())
      try {
          const response = await apiService.getAllWallets();
          return response.data;
        } catch (e) {
          return []
        } finally {
          dispatch(loadingSliceActions.stopLoading())
        }
    }

    getWallets().then((wallets) => {
      setWallets(wallets);
    })
  }, [user?.id])

  const columns = [
    {
      Header: 'Alias',
      accessor: 'walletAlias',
    },
    {
      Header: 'Wallet Address',
      accessor: 'walletAddress',
    },
    {
      Header: 'Balance',
      accessor: 'balance',
    },
    {
      Header: 'Asset',
      accessor: 'asset',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: { value: string }) => (
        <span className={`status-chip-account-details ${value?.toLowerCase() || ''}`}>
          {value}
        </span>
      ),
    },
    {
      Header: 'Transactions',
      accessor: 'transactions',
      Cell: ({ row }: { row: any }) => (
        <span 
          className="view-link" 
          onClick={() => handleViewTransactions(row.original)}
        >
          View
        </span>
      ),
    },
  ]

  const handleAddCrypto = () => {
    navigate('add-crypto-wallet');
  }

  const handleViewTransactions = (walletData: any) => {
    
   navigate('transactions-list', {
     state: {
      walletAlias: walletData.walletAlias,
       walletAddress: walletData.walletAddress,
       balance: walletData.balance,
       asset: walletData.asset,
       status: walletData.status,
     },
   });
 };
  return (
    <div className="wallet-management-page page-inner">
      <div className="page-header flex-row flex-align-space-between-center">
        <span className="page-title">Wallet Management</span>
        <Button
          label="Add Crypto Wallet"
          onClick={handleAddCrypto}
        ></Button>
      </div>
      <div className="flex-row flex-align-space-evenly-center flex-gap-30 md-flex-wrap">
        {walletCards.map((card, index) => (
          <WalletCard
            key={index}
            walletType={card.walletType}
            total={card.total}
            percentageChange={card.percentageChange}
            description={card.description}
            icon={card.icon}
          />
        ))}
      </div>
      <div className="page-content">
       { isLoading ? <LoadingSpinner/> :  <TableContent columns={columns} data={wallets || []}></TableContent> }
      </div>
    </div>
  )
}

export default WalletManagementPage;