import Button from "../../../../components/button";
import TableContent from "../../../../components/table-content";
import { ReactComponent as BitcoinIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthereumIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as UsdtIcon } from '../../../../assets/icons/usdt-rounded.svg';
import { ReactComponent as InitiateTransferIcon } from '../../../../assets/icons/initiate-transfer.svg';
import { useNavigate } from "react-router-dom";
import { AppStore } from "../../../../store/store";
import { UserState } from "../../../../store/user.slice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { apiService } from "../../../../services/api.service";
import { useDispatch } from "react-redux";
import { loadingSliceActions, LoadingState } from "../../../../store/loading.slice";
import LoadingSpinner from "../../../../components/loading-spinner";






const MyPaymentsPage = () => {
  const columns = [
    {
      Header: 'From',
      accessor: 'fromWalletAddress',
      Cell: ({ value }: { value: string }) => <span className="truncated-text">{value}</span>,
    },
    {
      Header: 'To',
      accessor: 'toWalletAddress',
      Cell: ({ value }: { value: string }) => <span className="truncated-text">{value}</span>,
    },
    {
      Header: 'Trans. Hash',
      accessor: 'txHash',
      Cell: ({ value }: { value: string }) => (
        <div className="hash-container">
          <span>{value}</span>
        </div>
      ),
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }: { value: string }) => <span>{value}</span>,
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }: { value: string }) => {
        switch (value) {
          case 'USDT':
            return <UsdtIcon />;
          case 'ETH':
            return <EthereumIcon />;
          case 'BTC':
            return <BitcoinIcon />;
          default:
            return null;
        }
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: { value: string }) => {
        let statusClass = '';
        switch (value) {
          case 'APPROVED':
            statusClass = 'active';
            break;
          case 'PENDING':
            statusClass = 'suspended';
            break;
          case 'Blocked':
            statusClass = 'closed';
            break;
          default:
            statusClass = 'unknown';
        }
        return <span className={`status-chip-account-details ${statusClass.toLocaleLowerCase()}`}>{value}</span>;
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: { row: any }) => (
        <span
          className="view-link"
        onClick={() => handleViewPayment(row.original.id)}
        >
          View
        </span>
      ),
    },
  ];

  const navigate = useNavigate();

  const { user } = useSelector<AppStore, UserState>(state => state.user)

  const { isLoading } = useSelector<AppStore, LoadingState>(state => state.loading)

  const dispatch = useDispatch();

  const [payments, setPayments] = useState([]);


  useEffect(() => {
    const getPayments = async () => {
      if (!user?.id) {
        return
      }
      dispatch(loadingSliceActions.startLoading())
      try {
      const response = await apiService.getAllPaymentByUser()
      return response.data;
      } catch(e) {
        console.log(e);
      } finally {
        dispatch(loadingSliceActions.stopLoading())
      }
    }

    getPayments().then((paymentsData) => {
      setPayments(paymentsData);
    })
  }, [user?.id])

  const handleViewPayment = (id: number) => {
    navigate('approve-transfer',{
      state: {
        id
      },
    })
  }

  const handleInitiateTransfer = () => {
    navigate('initiate-transfer');
  }


  return (
    <div className="tasks-for-approval-page page-inner">
      <div className="page-header flex-row flex-align-space-between-center">
        <span className="page-title">My Payments</span>
        <Button
          label="Initiate Transfer"
          icon={InitiateTransferIcon}
          onClick={handleInitiateTransfer}
        ></Button>
      </div>
      <div className="page-content">
       { isLoading ? <LoadingSpinner/> : <TableContent columns={columns} data={payments || []}></TableContent>} 
      </div>
    </div>
  )
}

export default MyPaymentsPage;