import { RouteObject } from "react-router-dom";
import providerManagementRouter from "./account-management/provider-management-router";
import TransactionHubPage from "./transaction-hub/transaction-hub-page";
import walletManagementRouter from './wallet-management/wallet-management-router'
import paymentRouter from "./payment/payment-router";
import DashboardPage from "./dashboard/dashboard-page";
import treasuryRouter from "./treasury/treasury-router";

    const privateRouter: RouteObject[] = [
        {
            path: '',
            element: <DashboardPage/>
        },
        {
            path: 'wallet-management',
            children: walletManagementRouter
        },
        {
            path: 'transaction-hub',
            element: <TransactionHubPage/>
        },
        {
            path: 'provider-management',
            children: providerManagementRouter,
                
        },
        {
            path: 'payment',
            children: paymentRouter
        },
        {
            path: 'treasury',
            children: treasuryRouter
        }
]

export default privateRouter;