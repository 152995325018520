 export interface RegisterAccount {
    accountNo: string;
    status: 'ACTIVE';
    wallets: WalletInfo[];
}

export enum Asset {
    BTC = 'BTC',
    ETH = 'ETH',
    USDT = 'USDT'
}


export interface Network {
    title: string;
    value: string;
    min: string;
    depositArrival: number;
    acceptedAssets: Asset[]
}


export enum WalletInternalType {
    MPC = 'MPC',
    DIRECT_CUSTODY = 'DIRECT_CUSTODY',
    EMBEDDED = 'EMBEDDED'
}


export interface WalletInfo {
    asset: string;
    walletAddress: string;
    walletAlias: string;
    network?: Network | null;
}

export interface WalletInfoDetails extends WalletInfo {
    walletType?: WalletType;
    id?: number;
    accountId?: number;
    status: 'ACTIVE' | 'SUSPENDED' | 'BLOCKED';
    balance?: string
}


export interface WalletCrypto extends WalletInfo {
    internalType: WalletInternalType
    privateKey: string;
}


export enum WalletType {
    EXT = 'EXTERNAL',
    INT = 'INTERNAL'
} 

 