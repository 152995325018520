import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutChart: React.FC = () => {
  const data = {
    labels: ['USDT', 'BTC', 'ETH'],
    datasets: [
      {
        label: 'Asset Distribution',
        data: [30, 30, 40],
        backgroundColor: [
          '#00B69B', 
          '#FCBE2D', 
          '#7F7F7F', 
        ],
        hoverOffset: 4,
        borderWidth: 0, 
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, 
      },
      tooltip: {
        enabled: true, 
      },
      datalabels: {
        color: '#fff',
        font: {
          size: 16,
          weight: 'bold' as const, 
        },
        formatter: (value: any, context: any) => {
          const label = context.chart.data.labels?.[context.dataIndex];
          return `${label}\n${value}%`; // Display currency on top and percentage below
        },
      },
    
    },
    cutout: '50%', 
  };

  const doughnutLabel = {
    id: 'doughnutLabel',
    beforeDatasetsDraw: (chart: any, args:any, pluginOptions: any) => {
      
      const { width, height, ctx } = chart;
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = chart._metasets[0].controller.innerRadius; // Adjust radius for a smaller circle

      ctx.fillStyle = '#ffffff'; // White background color
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      ctx.fill();
      const fontSize = '16px';
      const fontWeight = '600';
      ctx.fillStyle = 'rgba(39, 49, 66, 0.7)'; // White color for the text
      ctx.font = `${fontWeight} ${fontSize} sans-serif`;
      ctx.textBaseline = 'middle';

      const text1 = 'Asset';
      const text2 = 'Distribution';

      const text1X = Math.round((width - ctx.measureText(text1).width) / 2);
      const text2X = Math.round((width - ctx.measureText(text2).width) / 2);

      const text1Y = centerY - 10; 
      const text2Y = centerY + 10;

      ctx.fillText(text1, text1X, text1Y);
      ctx.fillText(text2, text2X, text2Y);

      ctx.save();
  }
}

 

  return (
      <Doughnut
       data={data} 
       options={options} 
       plugins={[doughnutLabel]} />
  );
};

export default DoughnutChart;
