import { useState, useEffect } from 'react';
import './side-menu.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStore } from '../store/store';
import { UserState } from '../store/user.slice';
import { ReactComponent as MenuOptionIcon } from '../assets/icons/menu-option.svg';
import { isParameter } from 'typescript';



interface MenuOption {
    label: string,
    path: string;
    options?: MenuOption[],
    guarded?: boolean
}


const menuitems: MenuOption[] = [
    {
        label: 'Dashboard',
        path: '/app'
    },
    {
        label: 'Wallet Management',
        path: 'wallet-management'
    },
    {
        label: 'Transaction Hub',
        path: 'transaction-hub'
    },
    {
        label: 'Provider Management',
        path: 'provider-management'
    },
    {
        label: 'Payment',
        path: 'payment',
        options: [
            {
                label: 'My Payments',
                path: 'payment'
            },
            {
                label: 'Tasks for Approval',
                path: 'payment/tasks-for-approval'
            },
        ],
        guarded: true
    },
    {
        label: 'Treasury',
        path: 'treasury',
        options: [
            {
                label: 'Asset Management',
                path: 'treasury'
            },
            {
                label: 'Liquidity Management',
                path: 'treasury/liquidity'
            },
            {
                label: 'Risk Management',
                path: 'treasury/risk'
            },
        ],
        guarded: false
    }
]


interface SideMenuProp {
    isOpen: boolean;
    toggleMenu: () => void;
}


const SideMenu: React.FC<SideMenuProp> = ({ isOpen, toggleMenu }) => {
    const [activeLink, setActiveLink] = useState('');
    const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useSelector<AppStore, UserState>(state => state.user)
    const isApprover = !!user?.groups?.find((group) => group =='approvers-group');
    
    useEffect(() => {
        const currentPath = location.pathname;

        const relevantPath = currentPath.replace('/app/', '');


        const matchingItem = menuitems.find(item => item.path && relevantPath.startsWith(item.path.replace('/app/', '')));


        if (matchingItem) {
            setActiveLink(matchingItem.label);
        } else {
            setActiveLink('Dashboard');
        }
    }, [location.pathname]);

    const handleLinkClick = (item: MenuOption) => {
        
        if (item.label == activeLink)
         {   return } else {
        if (item.path) {
            navigate(item.path);
        }
     }
        setActiveLink(item.label);
        if (item.options && isApprover) {
            setActiveSubmenu(item.options[0].label);
        } else {
            setActiveSubmenu(null); 
        }
       
        toggleMenu();
    };

    const handleOptionClick = (option: MenuOption) => {
        setActiveSubmenu(option.label)
        navigate(option.path);
    }
    
    return (
        <div className={`side-menu-container ${isOpen ? 'open' : ''}`}>

            <div className='menu-buttons-container main flex-column flex-align-center-start flex-gap-10'>
                {menuitems.map(item => {
                    if(item.options) {
                    }
                    return (
                    <div
                        key={item.label}
                        className={`item-container flex-row flex-gap-20 ${activeLink === item.label ? 'active' : ''}`}
                        onClick={() => handleLinkClick(item)}
                    >
                        <div className={`is-active-bar ${activeLink === item.label ? 'active' : ''}`}></div>
                        <div className={`button-container ${item.options && item.guarded === isApprover? 'has-options': ''} flex-column flex-align-center-center`}>
                            <span className='button-text'>{item.label}</span>
                            {item.options && item.guarded === isApprover && activeLink === item.label && (
                            <div className="submenu flex-column">
                                {item.options.map(option => (
                                    <div
                                        key={option.label}
                                        className={`submenu-item flex-row flex-gap-5 ${activeSubmenu === option.label ? 'active' : ''}`}
                                        onClick={() => handleOptionClick(option)}
                                    >   
                                        <MenuOptionIcon/>
                                        {option.label}
                                    </div>
                                ))}
                            </div>
                        )}
                        
                        </div>
                    </div>
                )})}
            </div>
            
        </div>
    )
}

export default SideMenu


