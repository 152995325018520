import { useForm } from "react-hook-form";
import AppFormInput from "../../../../components/form/app-form-input";
import { ReactComponent as AccountIcon } from '../../../../assets/icons/account.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add-icon.svg';
import { ReactComponent as RemoveWalletIcon } from '../../../../assets/icons/remove-wallet.svg';
import './add-provider-page.scss';
import Button from "../../../../components/button";
import PartAddWallet from "./add-account-page.part.add-wallet";
import { useEffect, useState } from "react";
import { WalletInfo, WalletInfoDetails, WalletType } from "../../../../models";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../../../services/api.service";



interface RegisterAccountForm {
    accountNo: string;
}


const AddAcountPage = () => {
    const navigate = useNavigate();
    const [walletAddresses, setWalletAddresses] = useState<WalletInfo[]>([]);
    const [selectedWallet, setSelectedWallet] = useState<WalletInfo | null | undefined>(undefined);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    const handleAddNewWallet = () => {
        setSelectedWallet(null);
    };

    const handleWalletClick = (wallet: WalletInfo) => {
        setSelectedWallet(wallet);
    };

    const handleRemoveWallet = (address: string) => {
        setWalletAddresses((prevWallets) => prevWallets.filter(wallet => wallet.walletAddress !== address));
        setSelectedWallet(undefined);
    };

    const handleSaveWallet = (newWallet: WalletInfo) => {
        if (selectedWallet) {
            setWalletAddresses((prevWallets) =>
                prevWallets.map((wallet) => (wallet.walletAddress === selectedWallet.walletAddress ? newWallet : wallet))
            );
        } else {
            setWalletAddresses((prevWallets) => [...prevWallets, newWallet]);
        }
        setSelectedWallet(undefined);
    }

    const handleCancel = () => {
        navigate(-1)
    }
    const form = useForm<RegisterAccountForm>({
        mode: 'onTouched',
        defaultValues: {}
    });

    const onSave = async (data: RegisterAccountForm) => {
        const wallets: WalletInfoDetails[] = walletAddresses.map((wallet) => {
            return { ...wallet, walletType: WalletType.EXT, status: 'ACTIVE' }
        })

        try {
            await apiService.createAccount({ ...data, wallets: wallets, status: 'ACTIVE' });
            navigate('/app/provider-management');
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="add-account-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Add New Provider</span>
            </div>
            <div className="page-content flex-row">
                <div className="account-info-container flex-column flex-align-start-start flex-gap-20">
                    <AppFormInput
                        name="accountNo"
                        control={form.control}
                        label="Provider Number"
                        validations={{ required: true }}
                        icon={<AccountIcon />}
                    />
                    <div className="add-wallet-container flex-row flex-align-start-center flex-gap-20">
                        <span
                            className="add-wallet-text"
                            onClick={handleAddNewWallet}
                        >Add Wallet </span>
                        <AddIcon
                            width={24}
                            height={24}
                            style={{ cursor: 'pointer' }}
                            onClick={handleAddNewWallet}
                        />
                        <div className="add-wallet-line"></div>
                    </div>
                    <div className="wallet-addresses-container flex-column flex-align-start-start flex-gap-15">
                        <span className="wallet-addresses-label">Wallet addresses</span>
                        {walletAddresses.map((wallet) => (
                            <div
                                key={wallet.walletAddress}
                                className={`wallet-address-container flex-row flex-align-start-center flex-gap-10 ${selectedWallet?.walletAddress === wallet.walletAddress ? 'selected' : ''}`}
                                onClick={() => handleWalletClick(wallet)}
                                style={{ cursor: 'pointer' }}
                            >
                                <span className="wallet-label">{wallet.walletAlias}</span>
                                <span style={{ fontWeight: 'bold' }}>{'|'}</span>
                                <span className="wallet-address">{wallet.walletAddress}</span>

                                {selectedWallet?.walletAddress === wallet.walletAddress ? (
                                    <RemoveWalletIcon
                                        style={{ marginLeft: '30px', cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveWallet(wallet.walletAddress);
                                        }}
                                    />
                                ) : <div style={{ marginLeft: '46px' }}></div>}
                            </div>
                        ))}
                    </div>
                    {isMobile && selectedWallet !== undefined && (
                        <PartAddWallet
                            wallet={selectedWallet}
                            onSave={handleSaveWallet}
                        />)
                    }
                    <div className="buttons-container flex-row flex-align-center-end flex-gap-30">
                        <Button
                            theme="form-outline"
                            label="Cancel"
                            onClick={() => handleCancel()}
                        />
                        <Button
                            theme="form"
                            label="Save"
                            disabled={!form.formState.isValid}
                            onClick={form.handleSubmit(onSave)}
                        />
                    </div>
                </div>
                {!isMobile && <div className="wallet-info-container flex-column flex-align-start-start flex-gap-20">
                    {selectedWallet !== undefined && (
                        <PartAddWallet
                            wallet={selectedWallet}
                            onSave={handleSaveWallet}
                        />
                    )}
                </div>
                }
            </div>
        </div>)
}

export default AddAcountPage;