import React, { useState } from 'react';
import './app-select.scss';
import { LabelValue } from '../../../types/label-value.types';
import {ReactComponent as CaretIcon} from '../../../assets/icons/simple-caret.svg'

interface DropdownProps<T = string> { 
  options: LabelValue<T>[];
  value?: T;
  onChange: (value: T) => void;
  placeholder?: string;
}

const AppSelect = <T,>({ options, value, onChange, placeholder }: DropdownProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: LabelValue<T>) => {
    onChange(option.value);
    setIsOpen(false);
  };
  
  return (
    <div className="app-dropdown-container form">
      <div className="dropdown-header" onClick={handleDropdownToggle}>
        <span>
        {value  ? options.find(option => option.value === value)?.label : placeholder}
        </span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}><CaretIcon width={16} height={10}/></span>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option) => (
            <div
              key={option.value?.toString()} 
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppSelect;
