import React from 'react';
import { Control, Controller, FieldValues, FieldPath, ValidationRule } from 'react-hook-form';
import './app-form-select.scss';
import AppSelect from './base/app-select';
import { LabelValue } from '../../types/label-value.types';
import { Message } from 'react-hook-form/dist/types/errors';
import AppFormElementContainer from './base/app-form-element-container';
import AppFormElementLabel from './base/app-form-element-label';

interface Validations {
    required: Message | ValidationRule<boolean>;
}

interface AppFormSelectProps<TForm extends FieldValues, TName extends FieldPath<TForm>, TValue extends (string | number)> {
    label?: string;
    control: Control<TForm>;
    name: TName;
    options: LabelValue<TValue>[];
    placeholder?: string;
    validations?: Partial<Validations>;
    onChange?: (option: string) => void;
}

const AppFormSelect = <TForm extends FieldValues, TName extends FieldPath<TForm>, TValue extends (string | number)>({
    label,
    control,
    name,
    options,
    placeholder,
    validations
}: AppFormSelectProps<TForm, TName, TValue>) => {
    return (
        <AppFormElementContainer className={`app-form-select `}>
            {label && (
                <AppFormElementLabel
                    name={name}
                    label={label}
                    validations={validations}
                />
            )}
            <Controller
                name={name}
                control={control}
                rules={{
                    required: validations?.required || false,
                }}
                render={({ field, fieldState }) => {
                    return (
                        <>
                            <AppSelect
                                options={options}
                                value={field.value || undefined}
                                onChange={(value) => field.onChange(value)}
                                placeholder={placeholder}
                            />
                            {fieldState.error?.type && <p className="form-element-error">{fieldState.error?.type}</p>}
                        </>
                    );
                }}
            />
        </AppFormElementContainer>
    );
};

export default AppFormSelect;
